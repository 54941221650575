import React, { useEffect, useState } from 'react';
import './ShipmentDetails.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMICheckbox, AMIFormElement, AMIInput, AMIPopover, AMISelect, Icon } from '../../../../ui-components/UiComponents';
import { appDataActions, appDataSelector } from '../../../../features/appDataSlice';
import { bookingActions, bookingSelector, ReasonForExport, ShipmentType, TermsOfSale } from '../../../../features/bookingSlice';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { checkFedexFreeDDP, getCarrierName, getOptionalChargeCost, OptionalCharge, selectTitleFormatter } from '../../../../utilities/HelperUtilities';
import {getRule, getTradeRoute, isIntraEu, TradeRoute} from '../../../../utilities/RulesEngineUtilities';
import { reasonsForExport } from './utilities';
import {getCurrencyConversionRate} from "../../../../utilities/APIUtilities";

const ShipmentDetails: React.FC<{
  errHandler: any;
  apiConfig: any;
}> = ({
  errHandler,
  apiConfig
}) => {

  const dispatch = useAppDispatch();
  const customer = useAppSelector(customerDetailsSelector);
  const booking = useAppSelector(bookingSelector);
  const appData = useAppSelector(appDataSelector);
  const quote = booking.selectedQuote;
  const countries = appData.countries;
  const showErrors = appData.showDetailsPageErrors;
  const tradeRoute = getTradeRoute(customer, booking);
  const carrier = getCarrierName(quote.quoteId);
  const ddpCharge = getOptionalChargeCost(booking.selectedQuote, OptionalCharge.DELIVERED_DUTY_PAID);
  const fedexFreeDDP = checkFedexFreeDDP(quote, customer);
  const bookingIsIntraEU :boolean = isIntraEu(booking);

  const [isDdpPopoverVisible, setIsDdpPopoverVisible] = useState(false);
  const [isFreightPopoverVisible, setIsFreightPopoverVisible] = useState(false);
  const [detailsRequired, setDetailsRequired] = useState(false);

  useEffect(() => {
    handleFreightChargeAutoPopulation().catch((error: any) => console.error(error));
    if (ddpCharge === 0 && !fedexFreeDDP) updateDDP(TermsOfSale.DAP);
  }, []);

  useEffect(() => {
    if (tradeRoute !== TradeRoute.DOMESTIC) {
      if (!bookingIsIntraEU) {
        setDetailsRequired(true);
      }
    } else {

      if (getRule(appData.customRules, 'windsorAgreement')) {
        setDetailsRequired(true)
      }
    }
  }, [appData.customRules]);

  const handleFreightChargeAutoPopulation = async () => {
    let freightChargeTotal = quote.rate.value + quote.charges.value - quote.discount.amount.value;

    if (booking.preferredCurrency.value !== quote.rate.currency) {
      const conversionRate = await getCurrencyConversionRate(apiConfig, quote.rate.currency, booking.preferredCurrency.value);
      freightChargeTotal = +freightChargeTotal * conversionRate;
    }

    if (
      !appData.isFreightChargeOverwritten
      && customer.countryOfResidence.value !== 'US'
      && customer.countryOfResidence.value !== 'ZA'
      && customer.countryOfResidence.value !== 'AU'
      && booking.shipmentType === ShipmentType.NON_DOCS
    ) dispatch(bookingActions.updateField({field: 'freightCharge', value: freightChargeTotal.toFixed(2)}));
    else if (!appData.isFreightChargeOverwritten) dispatch(bookingActions.updateField({field: 'freightCharge', value: ''}));
  }

  const onFreightChargeChange = (value: any) => {
    dispatch(bookingActions.updateField({field: 'freightCharge', value}));
    if (!appData.isFreightChargeOverwritten) dispatch(appDataActions.updateField({field: 'isFreightChargeOverwritten', value: true}));
  }

  const onChange = (field: string, event: any) => {
    let value = field === 'countryOfOrigin' ? event : event.value;
    dispatch(bookingActions.updateField({
      field,
      value
    }))
  }

  const updateDDP = (termsOfSale?: TermsOfSale) => {
    const newTermsOfSale = booking.customsDetails.invoiceDetails.termsOfSale === TermsOfSale.DDP ? TermsOfSale.DAP : TermsOfSale.DDP;
    dispatch(bookingActions.updateTermsOfSale(termsOfSale ? termsOfSale : newTermsOfSale));
  }

  const handleDdpCheck = () => {
    const newTermsOfSale = booking.customsDetails.invoiceDetails.termsOfSale === TermsOfSale.DDP ? TermsOfSale.DAP : TermsOfSale.DDP;
    dispatch(bookingActions.updateTermsOfSale(newTermsOfSale))
  }

  const getDdpText = () =>{
    let text: string;
    if (carrier === "dhl") text = `Deliver DDP from ${customer.countryOfResidence.currencySymbol}${ddpCharge.toFixed(2)}`;
    else if (fedexFreeDDP) text = 'Deliver DDP with no admin charges';
    else text = `Deliver DDP for ${customer.countryOfResidence.currencySymbol}${ddpCharge.toFixed(2)}`;
    return text;
  }

  const getDdpPopoverText = () =>{
    let text: string;
    if (carrier === "dhl") text = `DDp administration is charged at ${customer.countryOfResidence.currencySymbol}${ddpCharge.toFixed(2)}. If the duties and taxes amount to be charged is over ${customer.countryOfResidence.currencySymbol}${ddpCharge.toFixed(2)} then there is an additional charge of 2% of that amount minus the ${customer.countryOfResidence.currencySymbol}${ddpCharge.toFixed(2)} fee, which is billed on a supplementary invoice.`;
    else if (fedexFreeDDP) text = 'No DDP administration fee will be charged for this shipment, a minimum saving of £16 for this service. You will be billed for any duties and taxes on a supplementary invoice.';
    else text = `Check this box to be invoiced separately the import customs charges due for this shipment.`;
    return text;
  }

  return (
    <div
      className="shipment-details"
      id="shipment-details"
    >
      <p className="details-container__title">Shipment Details</p>

      {detailsRequired
        && <div className="shipment-details__reason-for-export-cont">
          <AMIFormElement
            label={
              tradeRoute === TradeRoute.IMPORT
                ? "Reason for Import"
                : "Reason for Export"
            }
            className="shipment-details__form-element"
            errorMessage={
              showErrors
              && !errHandler.reasonForExport.criteria
                ? errHandler.reasonForExport.message
                : ''
            }
          >
            <AMISelect
              name="reason-for-export"
              placeholder="Required"
              size="large"
              options={reasonsForExport}
              defaultValue={{
                title: selectTitleFormatter(booking.reasonForExport),
                value: booking.reasonForExport
              }}
              onChange={onChange.bind(null, 'reasonForExport')}
            />
          </AMIFormElement>

          {customer.countryOfResidence.value === "GB" && booking.reasonForExport === ReasonForExport.REPAIR_AND_RETURN
            && (
              <p className="shipment-details__reason-for-export-cont__text">For all controlled shipments, please contact
                customer services.</p>
            )}
        </div>
      }

      {detailsRequired
        && <AMIFormElement
        label="Country of Origin"
        className="shipment-details__form-element"
        errorMessage={
          showErrors
          && !errHandler.countryOfOrigin.criteria
            ? errHandler.countryOfOrigin.message
            : ''
        }
      >
        <AMISelect
          name="country-of-origin"
          placeholder="Required"
          size="large"
          isSearchable
          options={countries}
          onChange={(event) => onChange('countryOfOrigin', event)}
          defaultValue={booking.countryOfOrigin ? booking.countryOfOrigin : booking.origin}
        />
      </AMIFormElement>}

      {detailsRequired
        && <AMIFormElement
        label="Value for Customs"
        className="shipment-details__form-element"
      >
        <AMIInput
          name="value-for-customs"
          placeholder="Required"
          size="large"
          disabled
          value={booking.preferredCurrency.symbol + (+booking.totalShipmentValue).toFixed(2)}
        />
      </AMIFormElement>}

      <AMIFormElement
        label="HAWB Reference"
        className="shipment-details__form-element"
        errorMessage={
          showErrors
          && !errHandler.hawb.criteria
          ? errHandler.hawb.message
          : ''
        }
      >
        <AMIInput
          name="hawb-reference"
          placeholder="Required"
          size="large"
          value={booking.hawb}
          onChange={(event) => onChange('hawb', event.target)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Contents Description"
        className="shipment-details__form-element"
        errorMessage={
          showErrors
          && !errHandler.contentDescription.criteria
          ? errHandler.contentDescription.message
          : ''
        }
      >
        <AMIInput
          name="contents-description"
          placeholder="Required"
          size="large"
          value={booking.contentDescription}
          onChange={(event) => onChange('contentDescription', event.target)}
        />
      </AMIFormElement>

      {(getTradeRoute(customer, booking) !== TradeRoute.DOMESTIC || getRule(appData.customRules, 'windsorAgreement')) && !bookingIsIntraEU && (
        <AMIFormElement
          label={(
            <div className="options-page__labels">Freight Charge
              <span
                onMouseEnter={() => setIsFreightPopoverVisible(true)}
                onMouseLeave={() => setIsFreightPopoverVisible(false)}
              >
                <Icon
                  name="CircleQuestion"
                  color="var(--primary)"
                  style={{marginLeft: '4px'}}
                />
                <AMIPopover isVisible={isFreightPopoverVisible}>
                  {customer.countryOfResidence.value === 'US'
                    ? "Please complete this field to assist customs in calculating the duties for your shipment. If left empty, customs may calculate duties based on default shipping rates."
                    : "This charge has been automatically filled to assist destination customs to calculate duties for this shipment. This field can be edited if required. Please note if empty a default charge may be applied at customs."
                  }
                </AMIPopover>
              </span>
            </div>
          )}
          className="shipment-details__form-element"
          errorMessage={
            showErrors
            && !errHandler.freightCharge.criteria
            ? errHandler.freightCharge.message
            : ''
          }
        >
          <div className="shipment-details__currency-container">
            <div className="shipment-details__currency-symbol">{booking.preferredCurrency.title}</div>
            <AMIInput
              name="freight-charge"
              size="large"
              type="number"
              min="0"
              value={booking.freightCharge}
              onChange={(event) => onFreightChargeChange(event.target.value)}
            />
          </div>

        </AMIFormElement>
      )}

      {detailsRequired
        && !bookingIsIntraEU
        && (ddpCharge !== 0 || fedexFreeDDP)
        && (
          <AMIFormElement className="shipment-details__form-element shipment-details__checkbox">
          <AMICheckbox
            text=""
            checked={booking.customsDetails.invoiceDetails.termsOfSale === TermsOfSale.DDP}
            onChange={handleDdpCheck}
          >
            <div>
              {getDdpText()}
              <span
                onMouseEnter={() => setIsDdpPopoverVisible(true)}
                onMouseLeave={() => setIsDdpPopoverVisible(false)}
              >
                <Icon
                  name="CircleQuestion"
                  color="var(--primary)"
                  style={{marginLeft: '4px'}}
                />
                <AMIPopover
                  isVisible={isDdpPopoverVisible}
                >
                  {getDdpPopoverText()}
                </AMIPopover>
              </span>
            </div>
          </AMICheckbox>
        </AMIFormElement>
        )
      }
    </div>
  )
}

export default ShipmentDetails;