import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { appDataSelector, OwnOrGenerate } from '../../../../features/appDataSlice';
import { bookingActions, bookingSelector, ShipmentType, TransportMethod } from '../../../../features/bookingSlice';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { getCarrierName, getCommodityUrl, snakeCaseConvertor } from '../../../../utilities/HelperUtilities';
import CommoditiesWrapper from './commodities/CommoditiesWrapper';
import './CommoditiesCard.scss';
import { AMICheckbox, AMIFormElement, AMISelect } from '../../../../ui-components/UiComponents';
import { getRule } from "../../../../utilities/RulesEngineUtilities";

const CommoditiesCard: React.FC<{
  customRules: any
  errHandler: any
}> = ({
  customRules,
  errHandler
}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const showErrors = appData.showDetailsPageErrors;

  const isOptional = appData.invoice.ownOrGenerate !== OwnOrGenerate.GENERATE
    && booking.shipmentType !== ShipmentType.NON_DOCS
    && getRule(appData.customRules, 'commodityExemption')
    && !getRule(appData.customRules, 'windsorAgreement');

  const phytosanitaryIsVisible = booking.selectedQuote.transportMethod === TransportMethod.ROAD
    && getCarrierName(booking.selectedQuote.quoteId) === "dhl"
    && !getRule(appData.customRules, 'commodityExemption');

  return (
    <div className="commodities-card horizontal-card">

      <p className="horizontal-card__title">
        Commodities
        {isOptional && <span> (Optional)</span>}
      </p>

        {getRule(appData.customRules, 'windsorAgreement') && (
          <div className="commodities-card__windsor-agreement">
              <AMIFormElement
                label="Windsor Agreement Trade Lane"
              >
                  <AMISelect
                    name="windsor"
                    size="large"
                    options={[
                        {title: "Green lane", value: "green"},
                        {title: "Red lane", value: "red"},
                    ]}
                    defaultValue={{title: `${snakeCaseConvertor(booking.customsDetails.windsorFr)}`, value: booking.customsDetails.windsorFr}}
                    onChange={(event) => dispatch(bookingActions.updateField({
                        field: "customsDetails",
                        value: {
                            ...booking.customsDetails,
                            windsorFr: event.value
                        }
                    }))}
                  />
              </AMIFormElement>

          </div>
        )}

      {phytosanitaryIsVisible && (
        <AMIFormElement
          errorMessage={
            showErrors && !errHandler.phytosanitary.phytosanitaryCheck.criteria
              ? errHandler.phytosanitary.phytosanitaryCheck.message
              : ""
          }
          className="commodities-card__phytosanitary-check"
        >
          <AMICheckbox
            text="I confirm that this shipment contains no goods that are subject to excise taxes (tobacco, alcoholic beverages) & goods subject to government controls (drugs, medical devices and sanitary products, cosmetics, skincare and hygiene products, goods subject to phytosanitary or veterinary border controls, products for human consumption of all types, even being of non-animal origin, animal feeds. If the shipment is found to contain any of these items then it may be returned to me at my cost."
            checked={booking.phytosanitaryCheck}
            onChange={() => dispatch(bookingActions.updateField({
              field: "phytosanitaryCheck",
              value: !booking.phytosanitaryCheck
            }))}
          />
        </AMIFormElement>
      )}

      <p className="commodities-card__commodity-text">
        Find the correct commodity codes <a
          target="_blank"
          rel="noreferrer"
          href={getCommodityUrl(customer.countryOfResidence.value)}
        >here</a>
      </p>

      <CommoditiesWrapper customRules={customRules}/>

    </div>
  )

}

export default CommoditiesCard;