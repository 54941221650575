import React, { useEffect, useRef, useState } from 'react';
import './UploadDocument.scss';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { AMIAlert, AMIButton, AMIFormElement, AMISelect, Icon } from '../../../../../ui-components/UiComponents';
import { ElectronicTradeDocumentFormatType, ElectronicTradeDocumentType } from '../../../../../features/bookingSlice';
import { appDataActions, appDataSelector } from '../../../../../features/appDataSlice';
import { uploadDocumentOptions } from './utilities';
import { customerDetailsSelector } from '../../../../../features/customerSlice';

const UploadDocument: React.FC<{
  option: 'GENERATE' | 'UPLOAD'
}> = ({
  option
}) => {

  const dispatch = useAppDispatch();
  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);

  const inputRef = useRef<any>();

  const [uploadError, setUploadError] = useState('');
  const [dragActive, setDragActive] = useState(false);
  const [fileFlash, setFileFlash] = useState('');
  const [activeFileType, setActiveFileType] = useState({title: "Commercial Invoice", value: ElectronicTradeDocumentType.COMMERCIAL_INVOICE});
  const [filteredUploadDocumentOptions, setFilteredUploadDocumentOptions] = useState<any>([]);

  useEffect(() => {
    if(option === 'GENERATE') setActiveFileType({title: "Signature", value: ElectronicTradeDocumentType.SIGNATURE})
    getUploadDocumentOptions();
  }, [])

  const getUploadDocumentOptions = () => {
    let documentOptions = uploadDocumentOptions;
    if (customer.countryOfResidence.value === 'ZA') documentOptions = documentOptions.filter(el => el.value !== ElectronicTradeDocumentType.PRO_FORMA_INVOICE);
    setFilteredUploadDocumentOptions(documentOptions);
  }

  const onUpload = (event: any) => {
    event?.preventDefault();
    setUploadError('');
    inputRef.current.click();
  }

  const handleOnChange = (event: any) => {
    const files = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.name.length > 35) {
        setUploadError('The uploaded file name is too long please limit to 35 characters total including spaces and file extension (.jpg, .png etc.).');
        return;
      }
      const fileName = file.name;
      const rawType = file.type;
      const type = rawType.split('/')[1] as ElectronicTradeDocumentFormatType;
      if (file.size > 2097152) {
        setUploadError('File size is too large. Please upload a file less than 2MB');
        return;
      }
      if (!Object.values(ElectronicTradeDocumentFormatType).includes(type)) {
        setUploadError('File is an unsupported format. Please upload a PDF, PNG, GIF, TIFF or JPEG');
        return;
      }

      setFileFlash(fileName);
      setTimeout(() => setFileFlash(''), 500)

      const reader = new FileReader();

      reader.onload = function () {
        const base64String = (reader.result as string)?.replace("data:", "")
          .replace(/^.+,/, "");

          dispatch(appDataActions.addDocument({
            type: activeFileType,
            contentType: type,
            documentContent: base64String,
            documentDate: activeFileType.value === ElectronicTradeDocumentType.COMMERCIAL_INVOICE ? appData.invoice.date : "",
            reference: fileName
          }))
      }
      reader.readAsDataURL(file);
    }
  };

  const handleDrag = (event: any) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  }

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleOnChange(event)
    }
  };

  const onDragOver = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }

  const onFileTypeChange = (event: any) => {
    setActiveFileType(event);
  }

  const onTrashClick = (index: number) => {
    dispatch(appDataActions.deleteDocument(index))
  }
  return (
    <>
      {uploadError && (
        <AMIAlert
          variant="error"
          className="upload-alert"
        >
          {uploadError}
        </AMIAlert>
      )}

      <div className="upload-grid">

        <AMIFormElement label="Document Type">
          <AMISelect
            name="upload-file-type"
            size="large"
            defaultValue={activeFileType}
            options={filteredUploadDocumentOptions}
            hideClearButton
            onChange={onFileTypeChange}
          ></AMISelect>
        </AMIFormElement>

        <AMIButton
          variant='primary'
          size="large"
          disabled={appData.uploadedDocuments.length >= 10}
          className="invoice-upload-button"
          onClick={onUpload}
        >
          <Icon
            name="Upload"
            color='var(--textLight)'
          />
          Select File
        </AMIButton>

        {appData.uploadedDocuments.length < 10 && (
          <>
            <div
              className={
                dragActive
                ? "drag-and-drop-box drag-and-drop-box--active"
                : "drag-and-drop-box"
              }
              onDragEnter={handleDrag}
              onDrop={handleDrop}
              onDragOver={onDragOver}
              onDragLeave={handleDrag}
            >
              Drag & Drop Here to Upload
            </div>

            <input
              ref={inputRef}
              className="upload-input-btn"
              type="file"
              accept=".pdf,.png,.gif,.tiff,.jpeg"
              onChange={handleOnChange}
            />
          </>
        )}

      </div>

      <h3>Uploaded Documents {appData.uploadedDocuments.length} / 10</h3>
      <ul>
        {appData.uploadedDocuments?.length > 0 && (
          appData.uploadedDocuments.map((document: any, index: number) => {
            return (
              <li
                className="uploaded-file"
                key={document.reference + index}
                style={
                  fileFlash === document.reference
                    ? {animation: 'flash .2s infinite'}
                    : {animation: 'none'}
                }
              >
                {document.type.title}: <strong>{document.reference}</strong>
                <div className="document-delete-icon"style={{float: "right"}}>
                  <Icon
                    name="Trash"
                    onClick={() => onTrashClick(index)}
                  />
                </div>
              </li>
            )
          })
        )}
      </ul>
    </>
  )
}

export default UploadDocument;