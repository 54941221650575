import { AppData } from "../../../features/appDataSlice"
import { Booking, bookingActions, Country, ShipmentType } from "../../../features/bookingSlice"
import { CustomerDetails } from "../../../features/customerSlice"
import { deepEqualObjects, getCarrierName, trimIfString } from "../../../utilities/HelperUtilities"
import { getRule, isIntraEu } from "../../../utilities/RulesEngineUtilities";

export const taxNumberTypes = [
  {title: 'EORI', value: 'EORI'},
  {title: 'VAT', value: 'VAT'},
  {title: 'Deferment Account Number', value: 'DAN'},
  {title: 'Federal Tax (US)', value: 'US_FED_TAX'},
  {title: 'State Tax (US)', value: 'US_STATE_TAX'},
  {title: 'REX - EU registered exporter', value: 'REX'},
  {title: 'Driver\'s License ', value: 'DRIV_LIC'},
  {title: 'National Identity Card', value: 'NAT_ID'},
  {title: 'Passport Number', value: 'PASSPORT'},
  {title: 'Manufacturer ID', value: 'MANF_ID'},
  {title: 'IOSS', value: 'IOSS'},
  {title: 'Overseas Registered Supplier', value: 'OSEAS_REGD'},
  {title: 'Foreign UK VAT no.', value: 'FOREIGN_UK_VAT'},
  {title: 'AUSid GST', value: 'AUS_GST'},
  {title: 'E-commerce VAT', value: 'ECOMM_VAT'},
  {title: 'Free Trade Zone ID', value: 'FTZ'},
  {title: 'Employer Identification Number', value: 'EMP_ID_NO'},
  {title: 'Goods and Service Tax', value: 'GST'},
  {title: 'Social Security Number', value: 'SSN'},
  {title: 'DUNS - Data Universal Numbering System', value: 'DUNS'},
  {title: 'Brazil CNPJ', value: 'BR_CNPJ'},
  {title: 'Brazil CPF', value: 'BR_CPF'},
  {title: 'Brazil IE', value: 'BR_IE'},
  {title: 'Brazil RG', value: 'BR_RG'},
  {title: 'Brazil Passport', value: 'BR_PASSPORT'},
  {title: 'UK Internal Market Scheme', value: 'UKIMS'}
]

export const generateTaxNumberTypeTitle = (type: string) => {
  const taxNumberType = taxNumberTypes.find(el => el.value === type);
  return taxNumberType ? taxNumberType.title : '';
}

export const populateShipperDetails = (booking: Booking, dispatch: any) => {
  if (
    booking.selectedQuote.serviceType === 'COLLECTED'
    && booking.trueShipper
  ) {
    dispatch(bookingActions.setShipperDetails({
      ...booking.collectionDetails
    })
  )}
}

export const getInvoiceNumber = (booking: Booking, appData: AppData) => {
  let invoiceNumber: string | number | undefined = appData.invoice.number;
  if (!appData.invoice.number && !appData.isInvoiceNumberOverwritten) {
    if (booking.uploadedInvoice?.electronicTradeDocumentName) {
      invoiceNumber = booking.uploadedInvoice?.electronicTradeDocumentName;
    }
    if (booking.hawb && !booking.uploadedInvoice?.electronicTradeDocumentName) {
      invoiceNumber = booking.hawb;
    }
  }
  return trimIfString(invoiceNumber);
}

export const checkInvoiceNumber = (booking: Booking, appData: AppData, isInternational: boolean) => {
  const carrier = getCarrierName(booking.selectedQuote.quoteId);
  let shipmentType = booking.shipmentType;
  let result = true;

  if (
    carrier === 'dhl'
    && shipmentType === ShipmentType.NON_DOCS
    && isInternational
    && !isIntraEu(booking)
  ) {
    const invoiceNumber = getInvoiceNumber(booking, appData);
    result = !!invoiceNumber;
  }
  return result;
}

export const checkInvoiceDate = (booking: Booking, appData: AppData, isInternational: boolean) => {
  const carrier = getCarrierName(booking.selectedQuote.quoteId);
  let shipmentType = booking.shipmentType;
  let result = true;

  if (
    carrier === 'dhl'
    && shipmentType === ShipmentType.NON_DOCS
    && isInternational
    && !isIntraEu(booking)
  ) {
    const invoiceDate = appData.invoice.date;
    result = !!invoiceDate;
  }
  return result;
}

export const checkForTaxNumberErrors = (booking: any) => {
  let errors = false;
  const allTaxNumbers = booking.shipperDetails.taxNumbers.concat(booking.consigneeDetails.taxNumbers, booking.brokerDetails.taxNumbers);

  for (const taxNumber of allTaxNumbers) {
    if (taxNumber.value && !taxNumber.type) {
      errors = true;
      break;
    }
  }

  return errors;
}

export const compareAddresses = (
  {
    addressLine1,
    addressLine2,
    cityTown,
    companyName,
    contactName,
    countyStateProvince,
    email,
    postalCode,
    telephoneNumber
  }: any,
  country: Country,
  branchId: string,
  savedAddressSnapshot: any
) => {
  const reduxCollectionAddress = {
    addressLine1,
    addressLine2,
    branchId,
    cityTown,
    companyName,
    contactName,
    country,
    countyStateProvince,
    email,
    postalCode,
    telephoneNumber
  }

  const savedAddressCopy = {...savedAddressSnapshot};
  delete savedAddressCopy.id;

  return !!deepEqualObjects(savedAddressCopy, reduxCollectionAddress);
}

export const getAndSetWLAddress = (
  variant: 'ORIGIN' | 'DESTINATION',
  customer: CustomerDetails,
  booking: Booking,
  dispatch: any
) => {
  const action = variant === 'ORIGIN' ? 'setCollectionDetails' : 'setConsigneeDetails';
  const postalCodeToCheck = variant === 'ORIGIN' ? 'originPostalCode' : 'destinationPostalCode';
  const address = customer.creditCheck.whiteLabel ? customer.creditCheck.whiteLabel.address : null;

  if (address && address.postalCode === booking[postalCodeToCheck]) {
    dispatch(bookingActions[action]({
      addressLine1: address.streetLines[0],
      addressLine2: address.streetLines[1],
      addressLine3: address.streetLines[2],
      cityTown: address.city,
      countyStateProvince: address.state,
      postalCode: address.postalCode
    }))
  }
}

export const getCommoditiesVisible = (appData: AppData, setCommoditiesVisible: any) : void => {
  if (getRule(appData.customRules, 'commodityExemption')) {
    if (getRule(appData.customRules, 'windsorAgreement') || getRule(appData.customRules, 'docsShipmentValueRequired')) {
      setCommoditiesVisible(true);
    } else {
      setCommoditiesVisible(false);
    }
  } else {
    setCommoditiesVisible(true);
  }
};
