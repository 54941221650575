import React, {useState, FC, useRef, useEffect} from "react";
import './ChatWidget.scss'
import {useIsIframeLoaded} from "../../hooks/useIsIframeLoaded.hook";
import {CustomerDetails} from "../../features/customerSlice";
import type { EventHandlerPayload } from "@livechat/widget-react";
import { LiveChatWidget } from "@livechat/widget-react";

const ChatWidget: FC<{
  customer?: CustomerDetails
}> = ({customer}) => {
  function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
    console.debug('LiveChatWidget.onNewEvent', event)
  }

  const stormUrl = "https://www.timeforstorm.com/IM/endpoint/guest/5486/webchat/26723ed10285ebc0e55026d3da11bbc0ecaaaa7ade3b4dee6928f305667ae625?sourcePage=click2ship-uk"
  const [chatHidden, setChatHidden] = useState<boolean>(true);
  let [iframeKey, setIframeKey] = useState<any>(0);

  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const isIFrameLoaded = useIsIframeLoaded(iFrameRef);

  const [liveChat, setLiveChat] = useState<boolean>(true)

  const toggleChatHidden = () => setChatHidden(!chatHidden);
  const newChatWindow = () => setIframeKey(++iframeKey);

  useEffect(() => {
    if (customer?.customerCountryCode === 'GB') {
      setLiveChat(true)
    }
  }, [isIFrameLoaded])

  return (
    <div>
      {!liveChat
        &&
          <div id="storm-chat-widget-container">
              <button id="storm-openChatButton"
                      className="storm-chatButton"
                      onClick={toggleChatHidden}>
                  Open Chat
              </button>
              <button id="storm-closeChatButton"
                      className="storm-chatButton"
                      onClick={toggleChatHidden}
                      hidden={chatHidden}>
                  x
              </button>
              <button id="storm-newChatButton"
                      className="storm-chatButton"
                      onClick={newChatWindow}
                      hidden={chatHidden}>
                  New Chat
              </button>
              <iframe
                  ref={iFrameRef}
                  id="storm-chat-widget"
                  src={stormUrl}
                  title="AMI Chat"
                  hidden={chatHidden}
                  key={iframeKey}
              />
          </div>
      }

      {liveChat
        &&
          <LiveChatWidget
              license="3326012"
              visibility="minimized"
              onNewEvent={handleNewEvent}
          />
      }

    </div>
  )
};

export default ChatWidget;